import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TopHead from "../components/top-head"
import Phone from "../../static/phone.svg"
import Mail from "../../static/mail.svg"
import Pin from "../../static/pin.svg"
import { Helmet } from 'react-helmet'
import arrowSVG from "../../static/arrow.svg"

class Standort extends React.Component {

  render() {
    const { ort, tel, add, str, plz, mo, di, mi, don, fr, toggleDisplay, indState } = this.props
    const block = indState ? "max-h-1000" : "max-h-0"
    const anim = indState
      ? "max-height 0.7s ease-in-out"
      : "max-height 0.5s cubic-bezier(0, 1, 0, 1)"
    const arrow = indState ? "rotate(270deg)" : "rotate(90deg)"
    return (
      <>
        <button
          onClick={toggleDisplay}
          className={
            "focus:outline-none m-auto w-11/12 pt-5 pb-5 md:pt-4 md:pb-4 flex items-center justify-center"
          }
        >
          <h2 className="text-center text-lg opacity-75 hover:opacity-100 mr-5">{ort}</h2>
          <img
            src={arrowSVG}
            alt=""
            width="18px"
            style={{ transform: arrow, userDrag: "none", userSelect: "none" }}
            className=""
          ></img>
        </button>
        <div
          className={"w-11/12 m-auto overflow-hidden " + block}
          style={{ transition: anim }}
        >
          <section className="bg-white w-full z-10">
            <div className="text-center lg:mt-10 mt-6 flex flex-col items-center">
                <h2 className="text-2xl text-blue-500 mb-4">Unsere Öffnungszeiten</h2>
                <div className="flex space-x-10 sm:space-x-16 space justify-around border-blue-500 border-2 py-3 px-4">
                  <div className="text-left">
                    <h2 className="text-xl text-blue-500">Mo.</h2>
                    <h2 className="text-xl text-blue-500">Di.</h2>
                    <h2 className="text-xl text-blue-500">Mi.</h2>
                    <h2 className="text-xl text-blue-500">Do.</h2>
                    <h2 className="text-xl text-blue-500">Fr.</h2>
                  </div>
                  <div  className="text-right">
                    <h2 className="text-xl text-blue-500">{mo}</h2>
                    <h2 className="text-xl text-blue-500">{di}</h2>
                    <h2 className="text-xl text-blue-500">{mi}</h2>
                    <h2 className="text-xl text-blue-500">{don}</h2>
                    <h2 className="text-xl text-blue-500">{fr}</h2>
                  </div>
                </div>
            </div>
            <div className="flex md:flex-row flex-col w-full items-center mt-6">
                <div className="w-full flex flex-col items-center">
                    <div className="flex items-center mb-5">
                        <img src={Phone} alt="" className="mr-6 lg:w-10 w-8"></img>
                        <h2 className="text-xl text-blue-500">{tel}</h2>
                    </div>
                    <div className="flex items-center mt-5">
                        <img src={Mail} alt="" className="mr-6 lg:w-10 w-8"></img>
                        <h2 className="text-xl text-blue-500">info@irt-mtt.de</h2>
                    </div>
                </div>
                <div className="mt-10 mb-10 h-1 w-9/12 bg-blue-500 md:hidden"></div>
                <div className="mt-10 mb-10 h-56 w-2 bg-blue-500 md:block hidden"></div>
                <div className="mb-10 w-full flex flex-col items-center">
                    <img src={Pin} alt="" className="mb-5 lg:w-10 w-8"></img>
                    <div className="text-center">
                        <h2 className="text-xl text-blue-500">{str}</h2>
                        <h2 className="text-xl text-blue-500">{plz}</h2>
                        <h2 className="text-xl text-blue-500">{add}</h2>
                    </div>
                </div>
            </div>
        </section>
        </div>
        <div className="h-px w-11/12 m-auto bg-gray-200"></div>
      </>
    )
  }
}

class Standorte extends React.Component {
  state = {
    blockOpen: [false, false],
  };

  toggleDisplay = (i) => {
    let arr = this.state.blockOpen;
    if(arr[i]) {
      arr.fill(false);
    } else {
      arr.fill(false);
      arr[i] = true;
    }
    this.setState((prevState) => {
      return {blockOpen: arr};
    });
  }

  render() {
    return (
      <section className="md:max-w-6xl w-11/12 mb-10 shadow-xl pt-3 pb-6 bg-white rounded">
        <Standort
          toggleDisplay={() => this.toggleDisplay(0)}
          indState={this.state.blockOpen[0]}
          ort="Bad Homburg"
          tel="06172 6772293"
          str="Zeppelinstraße 24"
          plz="61352 Bad Homburg"
          add="gegenüber Hochtaunuskliniken"
          mo="07:00-20:00"
          di="07:00-20:00"
          mi="07:00-15:30"
          don="07:00-20:00"
          fr="07:00-17:00"
        />
        <Standort
          toggleDisplay={() => this.toggleDisplay(1)}
          indState= {this.state.blockOpen[1]}
          ort="Frankfurt am Main"
          tel="0162 6187207"
          str="Neue Kräme 26"
          plz="60311 Frankfurt am Main"
          add="APM Frankfurt"
          mo="08:00-15:30"
          di="11:00-19:30"
          mi="08:00-16:30"
          don="08:00-17:00"
          fr="08:00-15:30"
        />
      </section>
    )
  }
}

const Kontakt = (props) => (
    <>
    <Helmet title="Kontakt | IRT" defer={false} />
    <Layout>
        <TopHead photo={props.data.imageOne.childImageSharp.fluid} title="Unser Kontakt" para="Rufen Sie uns gerne an"/>
        <Standorte />
    </Layout>
    </>
)

export default Kontakt

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "photo1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
