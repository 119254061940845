import React from "react"
import Img from "gatsby-image"

const TopHead = ({ photo, title, para }) => (
  <section className="relative mt-10 mb-10 w-11/12 max-w-6xl overflow-hidden md:h-400 h-64">
    <Img fluid={photo} className="h-full opacity-75"/>
    <div className="flex items-center justify-center absolute inset-0">
        <div className="lg:max-w-5xl lg:w-7/12 w-11/12 text-center">
          <h1 className="text-white font-semibold md:text-5xl text-3xl z-10">{title}</h1>
          <p className="text-white font-medium md:text-2xl text-xl z-10 md:m-0 mt-5 ml-3 mr-3">{para}</p>
        </div>  
    </div>
  </section>
)

export default TopHead